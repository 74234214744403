<template>
  <div v-if="$canAndRulesPass(user, $permissions.SETTING_BRANDING.SEE_IN_MENU)">
    <page-heading
      :heading="pageTitle"
    >
      <span slot="help"></span>
      <span slot="right"></span>
    </page-heading>

    <el-card v-loading="loadingPage">

      <div class="branding__row">
        <div class="branding__row__setting-name">Favicon</div>
        <div v-if="$canAndRulesPass(user, $permissions.SETTING_BRANDING.UPDATE)">
          <import-upload
            entity="branding"
            tip="Доступный формат только png"
            accept="image/png"
            tight
            :data="{type: 'favicon'}"
            @import-succeed="importSucceed"
          ></import-upload>
        </div>
        <div style="margin-left: 50px">
          <img
            v-if="brandSettings.favicon"
            :src="'data:image/png;base64,' + brandSettings.favicon"
            height="60"
          />
        </div>
      </div>

      <div class="branding__row">
        <div class="branding__row__setting-name">Лого</div>
        <div v-if="$canAndRulesPass(user, $permissions.SETTING_BRANDING.UPDATE)">
          <import-upload
            entity="branding"
            tip="Доступный формат только png"
            accept="image/png"
            tight
            :data="{type: 'logo'}"
            @import-succeed="importSucceed"
          ></import-upload>
        </div>
        <div style="margin-left: 50px">
          <img
            v-if="brandSettings.logo"
            :src="'data:image/png;base64,' + brandSettings.logo"
            height="60"
          />
        </div>
      </div>

      <div class="branding__row">
        <div class="branding__row__setting-name">Лого CSS</div>
        <click-to-edit
          :disabled="!$canAndRulesPass(user, $permissions.SETTING_BRANDING.UPDATE)"
          style="width: 100%"
          v-model="brandSettings.logo_style"
          @input="saveSettingValue('branding.logo-style', $event)"
        ></click-to-edit>
      </div>
    </el-card>

    <el-card style="margin-top: 35px" v-loading="loadingPage">
      <div class="branding__row">
        <div class="branding__row__setting-name">Шрифт regular</div>
        <div v-if="$canAndRulesPass(user, $permissions.SETTING_BRANDING.UPDATE)">
          <import-upload
            entity="branding"
            tip="Доступный формат только ttf"
            tight
            :data="{type: 'font_regular'}"
            @import-succeed="importSucceed"
          ></import-upload>
        </div>
      </div>
      <div class="branding__row">
        <div class="branding__row__setting-name">Шрифт bold</div>
        <div v-if="$canAndRulesPass(user, $permissions.SETTING_BRANDING.UPDATE)">
          <import-upload
            entity="branding"
            tip="Доступный формат только ttf"
            tight
            :data="{type: 'font_bold'}"
            @import-succeed="importSucceed"
          ></import-upload>
        </div>
      </div>
    </el-card>

    <el-card style="margin-top: 35px" v-loading="loadingPage">

      <div class="branding__row">
        <div class="branding__row__setting-name">Primary цвет</div>
        <el-color-picker
          v-model="brandSettings.color_primary"
          :disabled="!$canAndRulesPass(user, $permissions.SETTING_BRANDING.UPDATE)"
          @change="saveSettingValue('branding.color-primary', $event)"
        ></el-color-picker>
        <div class="branding__row__color-desc">Используется как основной цвет. Большинство элементов системы будут оттенками данного цвета</div>
      </div>

      <div class="branding__row">
        <div class="branding__row__setting-name">Success цвет</div>
        <el-color-picker
          v-model="brandSettings.color_success"
          :disabled="!$canAndRulesPass(user, $permissions.SETTING_BRANDING.UPDATE)"
          @change="saveSettingValue('branding.color-success', $event)"
        ></el-color-picker>
        <div class="branding__row__color-desc">Используется в качестве цвета хорошего действия. Например, кнопка отправить на согласование или цвет галочки во сплывающем окне</div>
      </div>

      <div class="branding__row">
        <div class="branding__row__setting-name">Danger цвет</div>
        <el-color-picker
          v-model="brandSettings.color_danger"
          :disabled="!$canAndRulesPass(user, $permissions.SETTING_BRANDING.UPDATE)"
          @change="saveSettingValue('branding.color-danger', $event)"
        ></el-color-picker>
        <div class="branding__row__color-desc">Используется в качестве цвета плохого действия. Например, кнопка вернуть на доработку или цвет галочки во сплывающем окне с ошибкой</div>
      </div>

      <div class="branding__row">
        <div class="branding__row__setting-name">Радиус скругления элементов</div>
        <click-to-edit
          v-model="brandSettings.border_radius"
          :disabled="!$canAndRulesPass(user, $permissions.SETTING_BRANDING.UPDATE)"
          digits-only
          @input="saveSettingValue('branding.border-radius', $event)"
        ></click-to-edit>
        <div class="branding__row__color-desc">Определяет, насколько сильно в пикселях будут скруглены кнопки, окна и другие элементы системы</div>
      </div>


    </el-card>


    <el-card style="margin-top: 35px" v-loading="loadingPage">

      <div class="branding__row">
        <div class="branding__row__setting-name">Text regular цвет</div>
        <el-color-picker
          v-model="brandSettings.color_text_regular"
          :disabled="!$canAndRulesPass(user, $permissions.SETTING_BRANDING.UPDATE)"
          @change="saveSettingValue('branding.color-text-regular', $event)"
        ></el-color-picker>
        <div class="branding__row__color-desc">Стандартный цвет текста</div>
      </div>
      <div class="branding__row">
        <div class="branding__row__setting-name">Text secondary цвет</div>
        <el-color-picker
          v-model="brandSettings.color_text_secondary"
          :disabled="!$canAndRulesPass(user, $permissions.SETTING_BRANDING.UPDATE)"
          @change="saveSettingValue('branding.color-text-secondary', $event)"
        ></el-color-picker>
        <div class="branding__row__color-desc">Вторичный цвет текста. Используется, например, в заголовках таблиц или в подсказках</div>
      </div>
      <div class="branding__row">
        <div class="branding__row__setting-name">Primary button text цвет</div>
        <el-color-picker
          v-model="brandSettings.button_primary_font_color"
          :disabled="!$canAndRulesPass(user, $permissions.SETTING_BRANDING.UPDATE)"
          @change="saveSettingValue('branding.button-primary-font-color', $event)"
        ></el-color-picker>
        <div class="branding__row__color-desc">Цвет текста в кнопках с заливкой</div>
      </div>
      <div class="branding__row">
        <div class="branding__row__setting-name">Danger button text цвет</div>
        <el-color-picker
          v-model="brandSettings.button_danger_font_color"
          :disabled="!$canAndRulesPass(user, $permissions.SETTING_BRANDING.UPDATE)"
          @change="saveSettingValue('branding.button-danger-font-color', $event)"
        ></el-color-picker>
        <div class="branding__row__color-desc">Цвет текста в danger кнопках</div>
      </div>
      <div class="branding__row">
        <div class="branding__row__setting-name">Disabled button text цвет</div>
        <el-color-picker
          v-model="brandSettings.button_disabled_font_color"
          :disabled="!$canAndRulesPass(user, $permissions.SETTING_BRANDING.UPDATE)"
          @change="saveSettingValue('branding.button-disabled-font-color', $event)"
        ></el-color-picker>
        <div class="branding__row__color-desc">Цвет текста в заблокированных кнопках</div>
      </div>
      <div class="branding__row">
        <div class="branding__row__setting-name">Info button text цвет</div>
        <el-color-picker
          v-model="brandSettings.button_info_font_color"
          :disabled="!$canAndRulesPass(user, $permissions.SETTING_BRANDING.UPDATE)"
          @change="saveSettingValue('branding.button-info-font-color', $event)"
        ></el-color-picker>
        <div class="branding__row__color-desc">Цвет текста в инфо кнопках</div>
      </div>


    </el-card>


    <el-button
      v-if="$canAndRulesPass(user, $permissions.SETTING_BRANDING.UPDATE)"
      style="margin-top: 30px; width: 100%"
      type="primary"
      :loading="loadingPage"
      @click="rebuildFront"
    >Для применения изменений нажмите на кнопку. Система будет недоступна несколько минут
    </el-button>

  </div>
</template>

<script>
import PageHeading from "@/components/PageHeading";
import {mapGetters} from "vuex";
import ImportModal from "@/components/exportImport/ImportModal.vue";
import {companySettings} from "@/mixins/companySettings";
import ClickToEdit from "@/components/ClickToEdit.vue";
import ImportUpload from "@/components/exportImport/ImportUpload.vue";
import axios from "axios";

export default {
  name: 'Branding',
  mixins: [companySettings],
  components: {ImportUpload, ClickToEdit, ImportModal, PageHeading},

  computed: {
    ...mapGetters(['user']),
    pageTitle() {
      return document.title;
    },
  },
  data() {
    return {
      loadingPage: false,

      brandSettings: {
        logo: null,
        favicon: null,
        logo_style: null,
        color_primary: null,
        color_success: null,
        color_danger: null,
        border_radius: null,

        font_regular: null,
        font_bold: null,

        color_text_regular: null,
        color_text_secondary: null,

        button_primary_font_color: null,
        button_danger_font_color: null,
        button_disabled_font_color: null,
        button_info_font_color: null,
      }

    }
  },
  mounted() {
    this.getSettings();
  },
  methods: {
    getSettings() {
      this.loadingPage = true;
      this.$api.settings.getBrandSettings()
        .then(data => {
          this.brandSettings = data.settings;
        })
        .finally(() => {
          this.loadingPage = false;
        })
    },

    importSucceed() {
      this.getSettings();
    },


    rebuildFront() {
      this.loadingPage = true;
      this.$api.settings.rebuildFront()
        .finally(()=>{
          // location.reload();
        })
    },

  }
}

</script>


<style lang="scss">
.branding__row{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;

  &__setting-name{
    flex-basis: 200px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  &__color-desc{
    margin-left: 30px;
  }
}

</style>
