import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        get(payload){
            return requestSender('get', 'setting/get', payload);
        },
        save(payload){
            return requestSender('post', 'setting/save', payload);
        },

        getBrandSettings(payload){
            return requestSender('get', 'setting/get-brand-settings', payload);
        },
        rebuildFront(payload){
            return requestSender('post', 'setting/rebuild-front', payload);
        },

    }
}